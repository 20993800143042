import store from '@/store';

export function ensureRegion(region?: string): string {
    if (!region) {
        return store.state.regionCode;
    }

    return region;
}

export function uuidv4() {
    let d = new Date().getTime();
    let d2 = (performance && performance.now && (performance.now() * 1000)) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        let r = Math.random() * 16;
        if (d > 0) {
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        
        return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
    });
}

export function renderUiString(text: string): string {
    text = '<span>' + text.replace(/#([a-z])/g, '</span><span class="format-$1">') + '</span>';
    text = text.replace(/\\n/g, '<br/>');
    text = text.replace('\n', '<br/>');
    return text;
}

export function isDuck(): boolean {
    return !!process.env.VUE_APP_ISDUCK;
}

export function hasGrant(grant: string, grantList?: string[]): boolean {
    const grants = grantList || store.getters['grants'] as string[];
    for (const g of grants) {
        if (g === grant) {
            return true;
        }
        if (g === '*') {
            return true;
        }
        
        const split = grant.split('.');
        for (let i = 1; i < split.length; ++i) {
            const sub = split.slice(0, i).join('.') + '.*';
            if (sub === g) {
                return true;
            }
        }
    }
    
    return false;
}

export function placeTooltip(tooltip: HTMLDivElement, base: HTMLDivElement): Record<string, string> {
    let width = 0;
    let height = 0;
    if (tooltip) {
        width = tooltip.clientWidth;
        height = tooltip.clientHeight;
    }
    
    if (base) {
        const rect = base.getBoundingClientRect();
        let resultTop = 0;
        let resultLeft = 0;
        const padding = 20;
        
        if (rect.left + width + padding > window.innerWidth) {
            // resultLeft = window.innerWidth - width - padding;
            resultLeft = rect.left - width;
        } else {
            resultLeft = rect.left;
        }
        
        if (rect.bottom + height + padding > window.innerHeight) {
            resultTop = window.innerHeight - height - padding;
            resultLeft = rect.right;
            if (resultLeft < 0) {
                resultLeft = rect.right;
            } else if (resultLeft + width + padding > window.innerWidth) {
                resultLeft = rect.left - width;
            }
        } else {
            resultTop = rect.bottom;
        }
        
        return {
            top: `${resultTop}px`,
            left: `${resultLeft}px`,
        };
    }
    
    return {};
}
